import BingoNumberBuilder from "../components/BingoNumberBuilder";
import ContentLayout from "../layouts/ContentLayout";

export default function () {
  return (
    <ContentLayout title="Bingo numbers">
      <BingoNumberBuilder row={true} />
    </ContentLayout>
  );
}
