import styled, { css } from "styled-components";
import Ticket from "./components/Ticket";
import { addBingoNumber, resetAll, useStore } from "./store";
import BingoNumbers from "./components/BingoNumbers";
import { useState } from "react";
import InputModal from "./components/InputModal";
import MainLayout from "./layouts/MainLayout";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Tickets from "./pages/Tickets";
import AddTicket from "./pages/AddTicket";
import Settings from "./pages/Settings";
import Bingo from "./pages/Bingo";

function App() {
  return (
    <BrowserRouter>
      <MainLayout>
        <Routes>
          <Route path="/" exact Component={Tickets}></Route>
          <Route path="/add" exact Component={AddTicket}></Route>
          <Route path="/settings" exact Component={Settings}></Route>
          <Route path="/bingo" exact Component={Bingo}></Route>
        </Routes>
      </MainLayout>
    </BrowserRouter>
  );
}

export default App;

const AppStyled = styled.div`
  display: flex;

  flex-direction: column;
  // height: 100vh;
  // min-height: -webkit-fill-available;
  // max-height: -webkit-fill-available;
`;
const TicketList = styled.div`
  flex: 1;
  overflow: auto;
  height: 100%;
  min-height: 100vh;
  background: #eee;
`;
const Header = styled.div`
  height: 50px;
  border-bottom: 3px solid #ccc;
  display: flex;
  justify-content: space-between;
`;
export const Button = styled.button`
  border: none;
  padding: 5px 20px;
  line-height: 1;
  background: #000;
  color: #fff;
  border-radius: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  span {
    margin-left: 5px;
  }
  ${(p) =>
    p.big &&
    css`
      padding: 10px 30px;
      font-size: 16px;
    `}
`;
const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 5px 10px 30px;
  flex-direction: column;
  border-top: 3px solid #ccc;
  background: #fff;
  // position: sticky;
  bottom: 0;
  translated3d(0, 0, 0);
  // transform: translate(0);

`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  border: none;
  background-color: #eee;
  font-size: 14px;
  padding: 5px 10px;
`;
const Flex = styled.form`
  display: flex;
`;
