import styled, { css } from "styled-components";
import { addBingoNumber, bingoNumbersSelector, useStore } from "../store";

const numbers = Array(99)
  .fill()
  .map((_, i) => i + 1);

export default function ({ row = false }) {
  const bingoNumbers = useStore(bingoNumbersSelector);
  return (
    <Wrapper row={row}>
      {numbers.map((number) => (
        <Number
          key={number}
          onClick={() => addBingoNumber(number)}
          isSelected={bingoNumbers.includes(number)}
        >
          {number}
        </Number>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  margin-left: 1rem;
  padding-right: 15px;
  ${(p) =>
    p.row &&
    css`
      flex-direction: row;
      flex-wrap: wrap;
    `}
`;

const Number = styled.div`
  font-size: 1.1rem;
  width: 50px;
  padding: 10px;
  margin: 2px;
  text-align: center;
  ${(p) =>
    p.isSelected &&
    css`
      border-radius: 10px;
      background-color: #ccc;
    `}
`;
