import styled from "styled-components";
import { Button } from "../App";
import { bookmarkTicket, removeTicket } from "../store";
import { HiOutlineTrash } from "react-icons/hi";
import { HiBookmark, HiBookmarkSlash } from "react-icons/hi2";
export default function ({ number, bingoNumbers, idx, bookmark }) {
  return (
    <Wrapper>
      {number.map((row, idx) => (
        <Row>
          {row.map((col, idx) => (
            <Col isMatch={bingoNumbers.includes(col)}>{col}</Col>
          ))}
        </Row>
      ))}
      <Footer>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            removeTicket(idx);
          }}
        >
          <HiOutlineTrash fontSize={16} />
        </Button>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            bookmarkTicket(idx);
          }}
        >
          {bookmark ? (
            <HiBookmarkSlash fontSize={16} />
          ) : (
            <HiBookmark fontSize={16} />
          )}
        </Button>
      </Footer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-top: 1px solid #eee;
`;
const Row = styled.div`
  display: flex;
  justify-content: center;
`;
const Col = styled.div`
  width: 20px;
  height: 20px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1px;
  font-size: 12px;
  border-radius: 5px;

  ${(props) => props.isMatch && "background-color: orange;"}
`;
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 0;
`;
