import styled from "styled-components";
import { focusSelector, removeTicket, setFocus, useStore } from "../store";
import TickeNumber from "./TickeNumber";
import { Button } from "../App";
import { HiBookmark } from "react-icons/hi2";

function getWinArray(arr) {
  const win_array = [].concat(arr);
  for (var i = 0; i < 5; i++) {
    win_array.push([arr[0][i], arr[1][i], arr[2][i], arr[3][i], arr[4][i]]);
  }
  win_array.push([arr[0][0], arr[1][1], arr[2][2], arr[3][3], arr[4][4]]);
  win_array.push([arr[0][4], arr[1][3], arr[2][2], arr[3][1], arr[4][0]]);
  return win_array;
}

function isBingo(number, bingos) {
  var win_arr = getWinArray(number);

  var isBingo = false;
  for (var i = 0; i < win_arr.length; i++) {
    var a_win = win_arr[i];

    if (a_win.every((r) => bingos.includes(r))) {
      isBingo = true;
    }
  }
  return isBingo;
}

const countMatch = (number, bingoNumbers) => {
  const flattenNumbers = number.flat();

  const match = flattenNumbers.filter((num) => bingoNumbers.includes(num));
  return match.length;
};

export default function ({ id, title, number, idx, bookmark }) {
  const focus = useStore(focusSelector);
  const bingoNumbers = useStore((state) => state.bingoNumbers);
  const isFocus = true; // focus.includes(id);
  const isBingoResult = isBingo(number, bingoNumbers);
  return (
    <Wrapper isBingo={isBingoResult}>
      {bookmark && (
        <Absolute>
          <HiBookmark fontSize={16} />
        </Absolute>
      )}
      <Header
        onClick={() => {
          setFocus(id);
        }}
      >
        <div>{title ? title : "-"}</div>
        <Matcher>{countMatch(number, bingoNumbers)}</Matcher>
      </Header>
      {isFocus && (
        <TickeNumber
          number={number}
          bingoNumbers={bingoNumbers}
          idx={idx}
          bookmark={bookmark}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  padding: 0;
  background-color: #fff;
  border-radius: 10px;
  width: 138px;
  height: 190px;

  margin-right: 5px;
  float: left;

  ${(props) => props.isBingo && "background-color: #8bc34a;"}
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 10px;
  height: 30px;
`;
const Matcher = styled.div``;
const Absolute = styled.div`
  position: absolute;
  right: 5px;
  top: -4px;
`;
