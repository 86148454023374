import styled from "styled-components";
import { bingoNumbersSelector, removeBingoNumber, useStore } from "../store";

export default function () {
  const bingoNumbers = useStore(bingoNumbersSelector);
  return (
    <Wrapper>
      {bingoNumbers.map((number) => (
        <Number onClick={() => removeBingoNumber(number)}>{number}</Number>
      ))}
    </Wrapper>
  );
}
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Number = styled.div`
  font-size: 1.5rem;
  width: 40px;
  height: 40px;
  background-color: #eee;
  margin: 0 5px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-weight: 600;
  color: #666;
  cursor: pointer;
`;
