import styled from "styled-components";

export default function Header({ title, actions = [] }) {
  return (
    <HeaderStyled>
      <h1>{title}</h1>
      <div>{actions}</div>
    </HeaderStyled>
  );
}

const HeaderStyled = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  // background: #eee;
  h1 {
    font-size: 24px;
    margin: 0;
    font-weight: 800;
  }
`;
