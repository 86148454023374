import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "../App";
import { addTicket } from "../store";

function chunkArray(arr, size) {
  const chunkedArr = [];
  for (let i = 0; i < arr.length; i += size) {
    chunkedArr.push(arr.slice(i, i + size));
  }
  return chunkedArr;
}

var dfault = Array(25).fill(0);

const InputModal = ({ isOpen, onClose }) => {
  const [arr, setArr] = useState(dfault);
  const [focus, setFocus] = useState(0);
  const [title, setTitle] = useState("");

  if (!isOpen) {
    return null;
  }

  const onChange = (e, i, j) => {
    const newArr = [...arr];
    newArr[i * 5 + j] = parseInt(e.target.value);
    setArr(newArr);
  };

  const onPaste = (e) => {
    e.preventDefault();
    const clipboard = e.clipboardData.getData("Text");
    const newArr = [...arr];

    const clipArr = clipboard
      .split(/[\n,;.\s\t]/)
      .filter((item) => item != "")
      .map((n) => parseInt(n));
    console.log(clipArr);
    clipArr.forEach((n, idx) => {
      newArr[idx] = n;
    });
    setArr(newArr);
  };
  const onMoveAllItemsAtRightSideFromFocusToRightOneStep = () => {
    var _focus = focus;
    const newArr = [...arr];

    for (var i = 24; i > _focus; i--) {
      newArr[i] = newArr[i - 1];
    }
    newArr[_focus] = 0;

    setArr(newArr.splice(0, 25));
  };

  const reset = () => {
    setArr(dfault);
    setTitle("");
  };

  const _onClose = () => {
    reset();
    onClose();
  };
  const onSave = () => {
    addTicket({ title: title, number: chunkArray(arr, 5) });
    _onClose();
  };
  return (
    <ModalWrapper>
      <ModalContent>
        <div>
          <InputTitle
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            autoFocus
          ></InputTitle>
        </div>
        <hr />
        <div>
          {chunkArray(arr, 5).map((row, idx) => (
            <Row>
              {row.map((col, jdx) => (
                <Col>
                  <Input
                    type="number"
                    pattern="[0-9]*"
                    value={col}
                    onFocus={(e) => {
                      setFocus(idx * 5 + jdx);
                      e.target.select();
                    }}
                    onChange={(e) => onChange(e, idx, jdx)}
                    onPaste={(e) => onPaste(e)}
                    isFalse={col === 0}
                  ></Input>
                </Col>
              ))}
            </Row>
          ))}
        </div>
        <hr />
        <Flex>
          <Button
            onClick={() => onMoveAllItemsAtRightSideFromFocusToRightOneStep()}
          >
            {"move all ->"}
          </Button>
          <Button onClick={reset}>Reset</Button>
          <Button onClick={_onClose}>Close</Button>
          <Button onClick={onSave}>Save</Button>
        </Flex>
      </ModalContent>
    </ModalWrapper>
  );
};

export default InputModal;

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  width: 500px;
  background: white;
  padding: 10px 20px;
  //   border-radius: 10px;
`;
const Input = styled.input`
  background-color: #ddd;
  padding: 5px 0px;
  width: 50px;
  height: 50px;
  font-size: 25px;
  text-align: center;
  border: none;
  &:focus {
    border: 2px solid #000;
  }
  ${(props) => props.isFalse && "color: #f44336;"}
`;
const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const Col = styled.div`
  //   width: 40px;
  //   height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1px;
`;
const InputTitle = styled(Input)`
  width: 100%;
`;
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;
