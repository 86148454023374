import styled from "styled-components";
import Header from "../components/Header";

export default function ContentLayout({ children, title, padding = 1 }) {
  return (
    <ContentLayoutStyled>
      <Top>
        <Header title={title} />
      </Top>
      <ChildrenWrapper padding={padding}>{children}</ChildrenWrapper>
    </ContentLayoutStyled>
  );
}

const ContentLayoutStyled = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
`;
const ChildrenWrapper = styled.div`
  flex: 1;
  overflow: auto;
  padding: ${(p) => p.padding + "rem"};
  // background: #eee;
  width: 100%;
`;
const Top = styled.div``;
