import styled from "styled-components";
import BottomNavigation from "./BottomNavigation";

export default function MainLayout({ children }) {
  return (
    <MainLayoutStyled>
      <ChildrenWrapper>{children}</ChildrenWrapper>
      <BottomNavigation />
    </MainLayoutStyled>
  );
}

const MainLayoutStyled = styled.div`
  display: flex;
  flex-direction: column;

  bottom: 0;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate3d(0, 0, 0);
`;
const ChildrenWrapper = styled.div`
  flex: 1;
  overflow-y: scroll;
  background: #eee;

  width: 100%;
`;
