import Header from "../components/Header";
import styled from "styled-components";
import Ticket from "../components/Ticket";
import { addBingoNumber, resetAll, useStore } from "../store";
import BingoNumbers from "../components/BingoNumbers";
import { useState } from "react";
import InputModal from "../components/InputModal";
import ContentLayout from "../layouts/ContentLayout";
import BingoNumberBuilder from "../components/BingoNumberBuilder";

export default function Tickets() {
  const tickets = useStore((state) => state.tickets);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [numberInput, setNumberInput] = useState();
  const [isHideBingoNumbers, setIsHideBingoNumbers] = useState(false);
  const onAdd = (e) => {
    e.preventDefault();
    const number = parseInt(numberInput);
    if (isNaN(number) || !number) return;
    addBingoNumber(number);
    setNumberInput("");
  };

  return (
    <ContentLayout title="Tickets" padding={0}>
      <Column>
        <TicketList>
          {tickets && tickets.length === 0 && (
            <div style={{ textAlign: "center", padding: 20 }}>No ticket</div>
          )}
          {tickets &&
            tickets
              .sort((a, b) =>
                a.bookmark === b.bookmark ? 0 : a.bookmark ? -1 : 1
              )
              .map((ticket, idx) => (
                <Ticket {...ticket} idx={idx} key={ticket.id} />
              ))}
        </TicketList>
        <BingoNumberBuilder />
      </Column>

      <InputModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </ContentLayout>
  );
}

const TicketList = styled.div`
  flex: 1;
  height: 100%;
  overflow-y: auto;
  padding: 0rem 0 1rem 1rem;
  // flex-direction: row;
  // display: flex;
  flex-wrap: wrap;
`;

export const Button = styled.button`
  border: none;
  padding: 5px 20px;
  font-size: 12px;
  font-family: "Courier New", Courier, monospace;
  font-weight: bolder;
`;

const Column = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;
