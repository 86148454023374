import { Link } from "react-router-dom";
import styled from "styled-components";
import { HiCollection, HiPlusCircle } from "react-icons/hi";
import { HiCog } from "react-icons/hi2";
import { HiMiniCodeBracketSquare } from "react-icons/hi2";

export default function BottomNavigation() {
  return (
    <Nav>
      <LinkStyled to="/">
        <HiCollection fontSize={22} />
      </LinkStyled>
      <LinkStyled to="/add">
        <HiPlusCircle fontSize={22} />
      </LinkStyled>
      <LinkStyled to="/bingo">
        <HiMiniCodeBracketSquare fontSize={22} />
      </LinkStyled>
      <LinkStyled to="/settings">
        <HiCog fontSize={22} />
      </LinkStyled>
    </Nav>
  );
}

const Nav = styled.nav`
  //   position: fixed;
  //   bottom: 0;
  //   left: 0;
  width: 100%;
  display: flex;
  background-color: #f5f5f5;
  border-top: 1px solid #ccc;
  height: 68px;
  //   bottom: 0;
  //   translated3d(0, 0, 0);
`;

const LinkStyled = styled(Link)`
  color: #333;
  text-decoration: none;
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 18px;
  color: #333;
  &:hover,
  &:active,
  &:focus {
    color: #000;
  }
  -webkit-tap-highlight-color: transparent;
`;
