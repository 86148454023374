import { HiTrash } from "react-icons/hi";
import Header from "../components/Header";
import styled from "styled-components";
import { resetAll } from "../store";
import ContentLayout from "../layouts/ContentLayout";

export default function Settings() {
  return (
    <ContentLayout title={"Settings"}>
      {[
        {
          title: "Reset all data",
          icon: <HiTrash />,
          onClick: () => {
            resetAll();
          },
        },
      ].map((item, idx) => (
        <ItemWrapper key={idx} onClick={item.onClick}>
          <div className="icon">{item.icon}</div>
          <div className="title">{item.title}</div>
        </ItemWrapper>
      ))}
    </ContentLayout>
  );
}

const ItemWrapper = styled.div`
  display: flex;
  .icon {
    font-size: 20px;
  }
  .title {
    font-size: 16px;
    margin-left: 5px;
  }
`;
