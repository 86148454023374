import LightState from "react-light-state";

const store = new LightState(
  {
    tickets: [
      // {
      //   title: "Ticket 1",
      //   number: [
      //     [1, 2, 3, 4, 5],
      //     [6, 7, 8, 9, 10],
      //     [61, 62, 63, 64, 65],
      //     [21, 22, 23, 24, 25],
      //     [31, 32, 33, 34, 35],
      //   ],
      // },
      // {
      //   title: "Ticket 2",
      //   number: [
      //     [11, 12, 13, 14, 15],
      //     [21, 22, 23, 24, 25],
      //     [31, 32, 33, 34, 35],
      //     [41, 42, 43, 44, 45],
      //     [51, 52, 53, 54, 55],
      //   ],
      // },
    ],
    focus: [],
    bingoNumbers: [],
  },
  "bingoCheer",
  {
    storageName: "bingoCheer",
    getFromStorage: (key) => {
      const storage = JSON.parse(localStorage.getItem(key));
      return {
        tickets: [],
        bingoNumbers: [],
        ...storage,
        focus: [],
      };
    },
  }
);

const { dispatch, useStore, setState } = store;
export { dispatch, useStore, setState };

export const focusSelector = (state) => state.focus;
export const bingoNumbersSelector = (state) => state.bingoNumbers;

// action
export const setFocus = (id) => {
  setState((state) => {
    return {
      focus: state.focus.includes(id)
        ? state.focus.filter((i) => i !== id)
        : state.focus.concat(id),
    };
  });
};
export const removeBingoNumber = (number) => {
  setState((state) => {
    return {
      bingoNumbers: state.bingoNumbers.filter((n) => n != number),
    };
  });
};
export const addBingoNumber = (number) => {
  setState((state) => {
    return {
      bingoNumbers: state.bingoNumbers.includes(number)
        ? state.bingoNumbers.filter((n) => n != number)
        : state.bingoNumbers.concat(number),
    };
  });
};

export const addTicket = (ticket) => {
  setState((state) => {
    return {
      tickets: state.tickets.concat(ticket),
    };
  });
};

export const bookmarkTicket = (idx) => {
  setState((state) => {
    return {
      tickets: state.tickets.map((ticket, i) =>
        i === idx ? { ...ticket, bookmark: !ticket.bookmark } : ticket
      ),
    };
  });
};

export const removeTicket = (idx) => {
  setState((state) => {
    return {
      tickets: state.tickets.filter((_, i) => i !== idx),
    };
  });
};

export const resetAll = () => {
  setState({
    tickets: [],
    focus: [],
    bingoNumbers: [],
  });
  alert("All data has been reset");
};
